import request from "@/api/request";

export function getOrganizationColumns() {
    return request({
        url: `/ui-columns-order/by-organization`,
        method: 'get'
    })
}

export function setOrganizationColumns(data) {
    return request({
        url: `/ui-columns-order/by-organization`,
        method: 'post',
        data,
        headers: {
            'Content-type': 'text/plain'
        }
    })
}

export function deleteOrganizationColumns() {
    return request({
        url: `/ui-columns-order/by-organization`,
        method: 'delete'
    })
}


export function getUserColumns() {
    return request({
        url: `/ui-columns-order/by-user`,
        method: 'get'
    })
}

export function setUserColumns(data) {
    return request({
        url: `/ui-columns-order/by-user`,
        method: 'post',
        data,
        headers: {
            'Content-type': 'text/plain'
        }
    })
}

export function deleteUserColumns() {
    return request({
        url: `/ui-columns-order/by-user`,
        method: 'delete'
    })
}